import React, { useContext } from 'react';
import { Link } from 'gatsby';
import PortfolioContext from '../../context/context';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
  const { header } = useContext(PortfolioContext);

  return (
    <header className="header">
      <Container>
        <Navbar expand='md' bg='dark' data-bs-theme="dark" fixed="top" >
            <Navbar.Brand href='/' alt='Home Page Brand'>
            <StaticImage
              src="../../images/logotransparent.png"
              width={100}
              height={100}
              className="d-inline-block align-top"
              alt={header.alt}
              loading='eager'
              placeholder='none'
            />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav as='ul' className='m-auto'>
                    <Nav.Item as='li'>
                        <Link to='/about' activeClassName='active'>About</Link>
                    </Nav.Item>
                    <Nav.Item as='li'>
                        <Link to='/blog' activeClassName='active'>Blog</Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
};

export default Header;