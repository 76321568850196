import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fab);
library.add(fas);

const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <div className="back-to-top">
          <a href="#top" aria-label="Back To Top" >
            <FontAwesomeIcon icon={['fas', 'arrow-up']} size='2x' aria-hidden='true'/>
          </a>
        </div>
        <div className="social-links">
          {networks &&
            networks.map(network => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || 'https://github.com/cobidev/gatsby-simplefolio'}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <FontAwesomeIcon icon={['fab', `${name}`]} />
                </a>
              );
            })}
        </div>
        <hr />
        <p className="footer__text">
          © {new Date().getFullYear()} - Template developed by{' '}
          <a href="https://www.linkedin.com/in/nicholasjjeske/" target="_blank" rel="noopener noreferrer">
              Nick Jeske
          </a>
        </p>
        <div className='pages'>
          <p className="footer__text">
            <a href="/terms-of-use" rel="noopener noreferrer">
              Terms of Use
            </a>
          </p>
          <p className="footer__text">
            <a href="/privacy" rel="noopener noreferrer">
              Privacy
            </a>
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
