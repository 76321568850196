import React from "react";
import { headData } from "../../mock/data";
import { Helmet } from "react-helmet"

export const SEO = ({ title, author, keywords, description, pathname, twitterUsername, children }) => {
const { title: defaultTitle, author: defaultAuthor, keywords: defaultKeywords, lang: defaultLang, description: defaultDescription, image, siteUrl, twitterUsername: defaulTtwitterUsername } = headData;
  const seo = {
    title: title || defaultTitle,
    author: author || defaultAuthor,
    keywords: keywords || defaultKeywords,
    lang: defaultLang  || 'en',
    description: description || defaultDescription,
    image: `${siteUrl}/${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername: twitterUsername || defaulTtwitterUsername
  }

  return (
    <Helmet>
      <title>{seo.title}</title>
      <html lang={seo.lang} />
      <meta name="author" content={seo.author} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="website" />
      {children}
    </Helmet>
  )
};